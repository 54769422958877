// const metaDescriptions = {
//     '/': 'MH Cockpit, the top aviation college in Chennai and Tamil Nadu, specializes in advanced aviation training that sets students on the path to success. With an emphasis on industry-standard education and hands-on experience, expert instructors, and state-of-the-art facilities, we offer comprehensive programs for aspiring pilots and aviation professionals, laying the foundation for successful careers in the skies.',
//     '/training': "MH Cockpit offers comprehensive aviation training in partnership with India's Vels College. Prepare for exciting careers in airlines, airports, and more.",
//     '/private-pilot-license': "Discover the gateway to your aviation dreams with MH Cockpit, in partnership with Vels College, India's leading aviation training institute. Our Private Pilot License program offers world-class education led by industry veterans, with a track record of success. Join our diverse community of 1000+ trained students from 21 states and beyond, and kickstart your journey towards a rewarding career in aviation. With internships in Civil aviation and Defense units, our alumni soar to new heights, with freshers securing placements earning up to 33 LPA. Explore your potential with us today!",
//     '/commercial-pilot-license': "Embark on your journey to becoming a private pilot with MH Cockpit. Explore our comprehensive private pilot license program designed to equip you with the skills and knowledge needed for success in the skies. From rigorous training modules to expert guidance, unlock the freedom of flight with MH Cockpit today.",
//     '/typerating':"Elevate your career with MH Cockpit's type rating programs. Gain the expertise and certification required to operate specific aircraft models safely and efficiently. Explore our tailored training courses and soar to new heights in your aviation journey.",
//     '/airline-transport-pilot-license':"Achieve your dream of becoming an airline transport pilot with MH Cockpit. Our comprehensive ATP license program provides the rigorous training and hands-on experience necessary to excel in commercial aviation. Start your journey to commanding the skies with MH Cockpit today.",
//     '/dgca-license-conversion':"Navigate the process of DGCA license conversion seamlessly with MH Cockpit. Our expert guidance and streamlined procedures ensure a smooth transition for pilots seeking to convert their licenses to DGCA standards. Trust MH Cockpit for a hassle-free conversion experience.",
//     '/dgca-medical-requirements':"Stay informed and compliant with DGCA medical requirements at MH Cockpit. Discover the essential guidelines and procedures for obtaining and maintaining medical certification as per DGCA standards. Your aviation career begins with meeting the necessary medical prerequisites",
//     '/dgca-pilot-computer-number':"Obtain your DGCA pilot computer number efficiently with MH Cockpit. Navigate the application process seamlessly with our expert assistance and ensure compliance with DGCA regulations. Trust MH Cockpit to streamline your pilot computer number acquisition.",
//     '/bba-aviation':"Explore a career in aviation management with MH Cockpit's BBA program. Dive into comprehensive coursework tailored to the aviation industry, equipping you with the skills and knowledge needed for success in aviation management roles. Launch your career in aviation with MH Cockpit's BBA program today.",
//     '/bsc-aircraft-maintenance-science':"Embark on a journey into aircraft maintenance science with MH Cockpit's BSc program. Explore comprehensive coursework and hands-on training designed to equip you with the skills needed for a successful career in aircraft maintenance. Launch your career in aviation maintenance with MH Cockpit",
//     '/btech-aircraft-maintenance-engineering':"Pursue excellence in aircraft maintenance engineering with MH Cockpit's BTech program. Gain specialized knowledge and practical skills required for maintaining aircraft at the highest standards of safety and efficiency. Start your journey towards becoming a certified aircraft maintenance engineer with MH Cockpit.",
//     '/bsc-aeronautical-science':"Unlock the secrets of flight with MH Cockpit's BSc program in aeronautical science. Dive into advanced coursework and practical training to understand the intricacies of aeronautics and aviation technology. Prepare for a rewarding career in the aerospace industry with MH Cockpit.",
//     '/cabincrew':"Embark on a glamorous career in aviation as a cabin crew member with MH Cockpit. Explore our comprehensive training program designed to equip you with the skills and knowledge needed to excel in the skies. Join MH Cockpit and take your first step towards a rewarding career as cabin crew.",
//     '/fixedwing':"Experience the thrill of flight with MH Cockpit's fixed-wing training programs. From beginner to advanced levels, our comprehensive training prepares you for piloting fixed-wing aircraft with confidence and skill. Start your journey towards becoming a proficient fixed-wing pilot with MH Cockpit.",
//     '/agriculture':"Explore the intersection of aviation and agriculture with MH Cockpit's specialized programs. Discover how aviation technology is revolutionizing agriculture practices and embark on a career at the forefront of innovation. Join MH Cockpit and become a pioneer in agricultural aviation.",
//     '/company':"Learn more about MH Cockpit, your partner in aviation excellence. Discover our commitment to providing top-tier aviation training and services, as well as our dedication to shaping the future of aviation professionals. Partner with MH Cockpit for a successful career journey in aviation.",
//     '/aircraft-leasing-&-financing':"Navigate the complexities of aircraft leasing and financing with MH Cockpit. Explore our comprehensive services and expert guidance tailored to meet your aircraft leasing and financing needs. Trust MH Cockpit to help you achieve your aviation goals efficiently and effectively.",
//     '/flight-simulator':"Experience the ultimate in flight training with MH Cockpit's advanced flight simulators. Immerse yourself in realistic flight scenarios and hone your skills in a safe and controlled environment. Elevate your training with MH Cockpit's state-of-the-art flight simulator technology.",
//     '/cessna':"Explore the world of Cessna aircraft with MH Cockpit. From training to proficiency, we offer comprehensive programs tailored to Cessna pilots. Discover the excellence of Cessna aviation with MH Cockpit as your guide.",
//     '/airbus':"Unlock the potential of Airbus aircraft with MH Cockpit. Our specialized training programs cater to Airbus pilots, providing the skills and knowledge necessary for safe and efficient operation. Trust MH Cockpit for your Airbus aviation journey.",
//     '/boeing':"Take to the skies with confidence in Boeing aircraft, supported by MH Cockpit's expertise. Our tailored training programs are designed to empower Boeing pilots with the skills and knowledge required for successful flights. Partner with MH Cockpit for Boeing excellence",
//     '/mhintellect':"Experience the power of MH Cockpit's aviation intelligence solutions. From data analytics to predictive modeling, MH Intellect provides cutting-edge insights to optimize aviation operations. Harness the intelligence of MH Cockpit for smarter aviation decision-making.",
//     '/contactus':"Connect with MH Cockpit for all your aviation needs. Whether you're seeking training, information, or assistance, our dedicated team is here to help. Reach out to MH Cockpit and let us support your aviation journey.",
//     '/whatdowedo':"Discover the breadth of services offered by MH Cockpit. From training to consulting, we specialize in all aspects of aviation excellence. Explore how MH Cockpit can support your aviation goals today.",
//     '/gallery':"Immerse yourself in the world of aviation with MH Cockpit's gallery. Explore captivating images showcasing our training facilities, aircraft, and aviation events. Experience the excitement of aviation through MH Cockpit's gallery.",
//     '/pilotgroundinstructor':"Become a certified pilot ground instructor with MH Cockpit. Our comprehensive training programs prepare you to educate and mentor aspiring pilots, shaping the future of aviation. Start your journey towards becoming a pilot ground instructor with MH Cockpit. /ameinstructor",
//     '/ameinstructor':"Elevate your career as an aircraft maintenance engineer (AME) instructor with MH Cockpit. Our specialized training equips you with the skills and knowledge needed to train the next generation of AMEs. Join MH Cockpit and inspire future aviation professionals as an AME instructor.",
//     '/dronepilots':"Join the future of aviation as a certified drone pilot with MH Cockpit. Our comprehensive training programs cover all aspects of drone operation and regulation compliance. Launch your career in the exciting field of drone piloting with MH Cockpit.",
//     '/airportgroundoperationspersonnel':"Prepare for a dynamic career in airport ground operations with MH Cockpit. Our specialized training programs cover essential skills and knowledge required for various roles in airport operations. Start your journey towards becoming a vital part of airport ground operations with MH Cockpit.",
//     '/cabincrewinstructor':"Inspire the next generation of cabin crew members as a certified cabin crew instructor with MH Cockpit. Our comprehensive training programs provide you with the skills and knowledge needed to train aspiring cabin crew professionals. Join MH Cockpit and shape the future of cabin crew training.",
//     '/flightdispatch':"Master the art of flight dispatch with MH Cockpit's specialized training programs. From flight planning to operational control, our comprehensive curriculum prepares you for a rewarding career in flight dispatch. Start your journey towards becoming a certified flight dispatcher with MH Cockpit.",
//     '/it':"Explore the intersection of aviation and technology with MH Cockpit's IT solutions. From software development to network security, we provide cutting-edge IT services tailored to the aviation industry. Partner with MH Cockpit for innovative IT solutions in aviation.",
//     '/bdm':"Empower your aviation business with MH Cockpit's business development and management services. From strategy to execution, we offer comprehensive solutions to help your business thrive in the competitive aviation industry. Partner with MH Cockpit for business growth and success",
//     '/finance':"Optimize your aviation finances with MH Cockpit's specialized financial services. From budgeting to investment strategies, our expert team provides tailored financial solutions to support your aviation ventures. Partner with MH Cockpit for financial excellence in aviation.",
//     '/labestablishment':"Establish cutting-edge aviation training facilities with MH Cockpit's lab establishment services. From design to implementation, we provide comprehensive solutions to create state-of-the-art aviation training labs. Partner with MH Cockpit and set the standard for aviation training excellence.",
//     '/ourvalues':"Discover the core values that drive MH Cockpit's commitment to excellence in aviation. From integrity to innovation, our values guide every aspect of our operations and service delivery. Partner with MH Cockpit and experience the difference our values make.",
//     '/colleges':"Partner with MH Cockpit to shape the future of aviation education. Our collaboration opportunities for colleges and educational institutions offer access to cutting-edge aviation training programs and resources. Join forces with MH Cockpit to empower the next generation of aviation professionals.",
//     '/vels-university':"Explore aviation education at Vels University with MH Cockpit. Discover specialized programs designed to prepare students for dynamic careers in the aviation industry. Partner with MH Cockpit and Vels University for excellence in aviation education",
//     'https://mru.univirt.in/':"Unlock opportunities in aviation education at Malla Reddy University with MH Cockpit. Experience comprehensive training programs tailored to the needs of aspiring aviation professionals. Join MH Cockpit and Malla Reddy University for a transformative aviation education journey.",
//     '/jamal-mohamed-university':"Embark on a journey of aviation excellence at Jamal Mohamed University with MH Cockpit. Explore innovative aviation training programs designed to nurture the talents of future aviation leaders. Partner with MH Cockpit and Jamal Mohamed University for a rewarding aviation education experience.",
//     '/thank_you':"Thank you for engaging with MH Cockpit. We appreciate your interest in our aviation training programs and services. Stay connected with us for updates and insights into the exciting world of aviation.",
//     '/privacy-policy':"MH Cockpit is committed to protecting your privacy. Read our privacy policy to understand how we collect, use, and protect your personal information. Trust MH Cockpit for transparent and secure handling of your data.",
//     '/terms-and-conditions':"Review the terms and conditions governing the use of MH Cockpit's website and services. By accessing our website or engaging with our services, you agree to abide by these terms and conditions. Trust MH Cockpit for clear and fair terms of use.",
//   };
  
//   export default metaDescriptions;
  

const metaDescriptions = {

  '/': {
    description: 'MH Cockpit, the top aviation college in Chennai and Tamil Nadu, specializes in advanced aviation training that sets students on the path to success. With an emphasis on industry-standard education and hands-on experience, expert instructors, and state-of-the-art facilities, we offer comprehensive programs for aspiring pilots and aviation professionals, laying the foundation for successful careers in the skies.',
    keywords: ' Aviation College |  Best Aviation Colleges in India | Aviation Colleges in Chennai |   Best Aviation Colleges in Tamil Nadu | aviation courses | aviation courses near me | aviation pilot course | aviation certificate courses | airport ground staff course | aviation courses in chennai | airline pilot course',
    ogTitle: 'MH Cockpit - Best Aviation College in Tamilnadu - Chennai'
  },
  '/training': {
    description: "Get expert education and earn your personal pilot license with packages designed to make your dream a reality.",
    keywords: 'Aviation Training | Aviation Training Programs | Private Pilot License Training',
    ogTitle: 'Become a Pilot! Expert Aviation Training & Private License Programs'
  },
  '/private-pilot-license': {
    description: "Fulfill your dream of flying! Discover top private pilot license courses in Chennai, Tamil Nadu, and beyond with expert training and guidance.",
    keywords: 'Private Pilot License | Private Pilot License Requirements | Private Pilot License in Chennai | Private Pilot License in Tamil Nadu | Private Pilot License Training in India | Top Private Pilot License Courses | pilot course | pilot course in india | pilot training course',
    ogTitle: 'Your Path to the Skies : Private Pilot License Training in India'
  },
  '/commercial-pilot-license': {
    description: "Train with the best! Explore DGCA-approved pilot training centers, commercial aviation programs, and top-rated flight schools in Tamil Nadu. Soar today!",
    keywords: 'commercial pilot training | best commercial flight schools | Commercial aviation | Commercial pilot training in Tamil Nadu | DGCA pilot training centers in india | commercial pilot course | cpl courses | commercial pilot course in india',
    ogTitle: 'Launch Your Career: Top Commercial Pilot Training in Tamil Nadu & India'
  },
  '/typerating': {
    description: "Become a certified pilot with the best type rating courses in India. Train at leading flight schools in Chennai and Tamil Nadu for your dream career in aviation.",
    keywords: 'type rating training | Pilot Type Rating Chennai | aircraft type rating certification | type rating flight school | type rating courses in India | best type rating training centers in Tamil Nadu | Type Rating Institutes in Chennai',
    ogTitle: 'Master Type Rating Training at Top Institutes in Chennai, Tamil Nadu'
  },
  '/airline-transport-pilot-license': {
    description: "Kickstart your airline pilot journey with the best ATPL training in Chennai, Tamil Nadu. Join top-rated institutes and flight schools to achieve your pilot dreams!",
    keywords: 'ATPL Training in Chennai | Airline Pilot Training in India | Best ATPL Institutes in Tamil Nadu | ATPL Training Colleges | ATPL Training Programs | Best ATPL Flight Schools | Apply for ATPL Training | ATPL Training Institutes in India | airline courses | airline management courses',
    ogTitle: 'Launch Your Pilot Career with ATPL Training in Chennai & India'
  },
  '/dgca-license-conversion': {
    description: "Simplify your DGCA license conversion with expert training, flexible packages, and the best courses in Chennai and Tamil Nadu. Your career upgrade starts here!",
    keywords: 'DGCA License Conversion in Chennai | DGCA License Conversion Services | DGCA Conversion Training | DGCA Conversion Institute in Tamil Nadu | Best DGCA License Conversion Institutes | DGCA License Conversion Packages | DGCA License Conversion Courses | DGCA License Conversion College in India',
    ogTitle: 'Ace Your DGCA License Conversion with Top Institutes in Tamil Nadu'
  },
  '/dgca-medical-requirements': {
    description: "Discover the complete process, tests, and standards for DGCA medical requirements in India. Your guide to acing the medicals for a successful aviation career!",
    keywords: 'DGCA Medical Requirements in India | DGCA Medical Requirements for Pilots | DGCA Medical Tests for Pilot License | DGCA Medical Standards for Aviation | DGCA Medical Requirements for CPL | DGCA Medical Requirements | DGCA Medical Requirements Process',
    ogTitle: 'Everything You Need to Know About DGCA Medical Requirements for Pilots'
  },
  '/dgca-pilot-computer-number': {
    description: "Need your DGCA Pilot Computer Number? From registration to verification, get step-by-step guidance, fees info, and expert tips to streamline the process today!",
    keywords: 'DGCA Pilot Computer Number Chennai | DGCA Pilot Number Verification Chennai | DGCA Computer Number Registration Tamil Nadu | DGCA Pilot Computer Number Process | DGCA Pilot Computer Number Details | DGCA Computer Number Explained | DGCA Computer Number Registration | DGCA Pilot Computer Number Fees | DGCA Pilot Computer Number Cost',
    ogTitle: 'Simplify Your DGCA Pilot Computer Number Registration in Tamil Nadu'
  },
  '/bba-aviation': {
    description: "Find the best BBA Aviation colleges in Chennai and India, offering top courses, expert training, and guaranteed placements to kickstart your aviation career!",
    keywords: 'BBA Aviation Colleges in Chennai | Top BBA Aviation Institutes in India | BBA Aviation Courses Chennai | Best BBA Aviation College in Tamil Nadu | BBA Aviation Training Institutes | BBA Aviation Admission Process | Best BBA Aviation Courses in India | BBA Aviation Colleges with Placement | BBA in Aviation Management',
    ogTitle: 'Discover Top BBA Aviation Colleges in Chennai & India with Placement Opportunities'
  },
  '/bsc-aviation': {
    description: "Looking for top BSc Aviation colleges? Explore the best universities, courses, and admissions for 2025 in India & Chennai now!",
    keywords: 'Top BSc Aviation Colleges in India | Best BSc Aviation Colleges Tamil Nadu | BSc Aviation Admission 2025 India | BSc Aviation Institutes in Chennai | BSc Aviation Courses in Chennai | BSc Aviation Course Syllabus | Best BSc Aviation Degree Programs India | Best Universities for BSc Aviation | BSc Aviation Courses | BSC Aviation',
    ogTitle: 'Discover the Best BSc Aviation Colleges in India & Tamil Nadu for 2025'
  },
  // '/bsc-aircraft-maintenance-science': {
  //   description: "Discover top-rated aircraft maintenance schools, degree programs, and placement opportunities in Tamil Nadu and Chennai. Elevate your career in aviation with expert training!",
  //   keywords: 'Best Colleges for Aircraft Maintenance in Tamil Nadu | Top Aircraft Maintenance Schools in Chennai | Aircraft Maintenance Degree | Top 10 Aircraft Maintenance Colleges India | Aircraft Maintenance Courses | BSc Aircraft Maintenance Course in Chennai | BSc Aircraft Maintenance Course in Tamil Nadu | BSc Aircraft Maintenance Placement Programs',
  //   ogTitle: 'Discover the Best Aircraft Maintenance Colleges & Courses in Tamil Nadu & Chennai'
  // },
  // '/btech-aircraft-maintenance-engineering': {
  //   description: "Pursue excellence in aircraft maintenance engineering with MH Cockpit's BTech program. Gain specialized knowledge and practical skills required for maintaining aircraft at the highest standards of safety and efficiency. Start your journey towards becoming a certified aircraft maintenance engineer with MH Cockpit.",
  //   keywords: '',
  //   ogTitle: ''
  // },
  '/bsc-aeronautical-science': {
    description: "Explore the best BSc Aeronautical Science programs in Chennai and Tamil Nadu with top-ranked colleges and exciting placement opportunities.",
    keywords: 'BSc Aeronautical Science | Aeronautical degree in India | Top universities for Aeronautics | BSc Aeronautical Science in Chennai | Aeronautical degree colleges in Tamil Nadu | Aeronautics colleges in India | Aeronautical colleges with placements | Top aeronautical universities in Chennai | Best aeronautical colleges in Tamil Nadu | Best aeronautical degree programs | Aviation courses with placements in Chennai | Top-ranked aeronautical colleges Tamil Nadu | BSC Aeronautical Science',
    ogTitle: 'Discover Top Aeronautical Science Degrees & Universities in India, Especially Chennai & Tamil Nadu'
  },
  '/mba-aviation-management': {
    description: "Explore top MBA Aviation programs across India and Chennai, offering world-class education and career opportunities in aviation management.",
    keywords: 'MBA Aviation Management Colleges in Chennai | Best MBA Aviation Courses in Tamil Nadu | Top MBA Aviation Colleges in India | MBA Aviation Programs | Top 10 MBA Aviation Institutes in Tamil Nadu | Top MBA Aviation Colleges in India | Best Institutes for MBA in Aviation | aviation management courses | MBA in Aviation Management',
    ogTitle: 'Discover the Best MBA Aviation Colleges & Courses in India and Chennai'
  },
  '/mba-hr-marketing-and-finance': {
    description: "Discover the best MBA Marketing & Finance programs in India, Chennai, and Tamil Nadu. Take your career to the next level with top colleges and training institutes",
    keywords: 'Best MBA Marketing and Finance in India | Best MBA Marketing and Finance in Chennai | Best MBA Marketing and Finance in Tamil Nadu | Best MBA Marketing Colleges in Chennai | Top MBA HR Colleges in India | Top MBA Marketing Training Institutes | Best MBA HR Placement Courses India | MBA HR Programs in Tamil Nadu | MBA Marketing | MBA Finance',
    ogTitle: 'Unlock Top MBA Marketing & Finance Courses in India, Chennai & Tamil Nadu'
  },
  '/PG-Diploma-in-AME': {
    description: "Choose the best PG diploma in AME colleges with advanced programs tailored to industry standards.",
    keywords: 'PG Diploma in AME Chennai | Best AME Colleges in Tamil Nadu | Top AME Diploma Institutes in India | Best PG Diploma in AME Colleges | Apply for AME PG Diploma in Chennai | AME PG Diploma Programs Tamil Nadu | Top AME Training Centers in Chennai',
    ogTitle: 'Best PG Diploma in AME Colleges for Career Growth'
  },
  '/diploma-in-airport-management': {
    description: "Discover top-ranked airport management institutes offering comprehensive programs for your success.",
    keywords: 'Diploma in Airport Management Chennai | Best Airport Management Institutes Tamil Nadu | Top Airport Management Institutes | Airport Management Colleges in India | Best Airport Management Courses | Best Airport Management Courses in Chennai | Best Diploma in Airport Management Courses | Advanced Diploma in Airport Management | Airport Management Programs | Airport Management Diploma | aviation diploma courses',
    ogTitle: 'Join Tamil Nadu Best Airport Management Institutes'
  },
  '/btech-aerospace-engineering': {
    description: "Explore the best B.Tech Aerospace colleges, institutes, and universities in Tamil Nadu and Chennai. Start your journey in aerospace engineering with top-rated programs designed for success!",
    keywords: 'B.Tech Aerospace Chennai | Best colleges for B.Tech Aerospace in India | Aerospace Engineering Tamil Nadu | Aerospace courses | B.Tech Aerospace colleges | Best Aerospace engineering colleges | Best Aerospace universities in Tamil Nadu | B.Tech Aerospace course | B.Tech Aerospace institutes | B.Tech Aerospace institutes in Chennai | Top-rated Aerospace courses Tamil Nadu',
    ogTitle: 'Top B.Tech Aerospace Colleges and Courses in Tamil Nadu'
  },
  '/cabincrew': {
    description: "Embark on a glamorous career in aviation as a cabin crew member with MH Cockpit. Explore our comprehensive training program designed to equip you with the skills and knowledge needed to excel in the skies. Join MH Cockpit and take your first step towards a rewarding career as cabin crew.",
    keywords: 'Flight attendant courses in Chennai | Best aviation academies | Air hostess training | Cabin crew institutes in India | Aviation training in Tamil Nadu | Cabin crew courses in Tamil Nadu | Air hostess institutes Chennai | Best cabin crew training academy | Top air hostess institutes in India | cabin crew training | Flight attendant training | Cabin crew diploma programs | Best aviation schools for cabin crew | cabin crew course | cabin crew courses near me',
    ogTitle: ''
  },
  '/fixedwing': {
    description: "Experience the thrill of flight with MH Cockpit's fixed-wing training programs. From beginner to advanced levels, our comprehensive training prepares you for piloting fixed-wing aircraft with confidence and skill. Start your journey towards becoming a proficient fixed-wing pilot with MH Cockpit.",
    keywords: 'Electric aircraft technology | Fixed-wing electric planes | Electric aviation innovation | Advanced battery electric planes | Green aviation technology | Hybrid-electric propulsion systems | Future of electric fixed-wing aviation',
    ogTitle: 'Expert Flight Attendant Training for a Promising Career'
  },
  '/agriculture': {
    description: "Explore the intersection of aviation and agriculture with MH Cockpit's specialized programs. Discover how aviation technology is revolutionizing agriculture practices and embark on a career at the forefront of innovation. Join MH Cockpit and become a pioneer in agricultural aviation.",
    keywords: '',
    ogTitle: ''
  },
  '/company': {
    description: "Learn more about MH Cockpit, your partner in aviation excellence. Discover our commitment to providing top-tier aviation training and services, as well as our dedication to shaping the future of aviation professionals. Partner with MH Cockpit for a successful career journey in aviation.",
    keywords: '',
    ogTitle: ''
  },
  '/aircraft-leasing-&-financing': {
    description: "Navigate the complexities of aircraft leasing and financing with MH Cockpit. Explore our comprehensive services and expert guidance tailored to meet your aircraft leasing and financing needs. Trust MH Cockpit to help you achieve your aviation goals efficiently and effectively.",
    keywords: 'Aircraft Financing Services Chennai | Top Benefits of Aircraft Leasing in India | Commercial Aircraft Leasing in Tamil Nadu | Aircraft Leasing Companies in Chennai | Aircraft Financing Providers',
    ogTitle: 'Reliable Aircraft Financing Services in Chennai - Easy Leasing'
  },
  '/flight-simulator': {
    description: "Experience the ultimate in flight training with MH Cockpit's advanced flight simulators. Immerse yourself in realistic flight scenarios and hone your skills in a safe and controlled environment. Elevate your training with MH Cockpit's state-of-the-art flight simulator technology.",
    keywords: 'Flight Simulator | Flight Simulator Training Guide | Flight Simulator Training Centers | Flight Simulators in Chennai | Best Flight Simulators in Tamil Nadu | Flight Simulators in Aviation | Aviation Academies with Flight Simulators | Top Flight Simulators in India | Flight Simulator Facilities in Chennai | Best Commercial Flight Simulators | Professional Flight Simulator Kit',
    ogTitle: 'Complete Guide to Flight Simulator Training for Beginners'
  },
  '/cessna': {
    description: "Explore the world of Cessna aircraft with MH Cockpit. From training to proficiency, we offer comprehensive programs tailored to Cessna pilots. Discover the excellence of Cessna aviation with MH Cockpit as your guide.",
    keywords: 'Cessna Training | Best Cessna Flight Schools | Cessna Pilot Training | Cessna Certification in Chennai | Cessna Simulator Training | Cessna Flying Clubs in Tamil Nadu | Best Places to Learn Cessna Flying | Cessna 172 for Sale | Cessna 152 Models | Best Cessna Aircraft for Beginners | Top Rated Cessna Dealers in India',
    ogTitle: 'Best Places to Learn Cessna Flying and Become a Pilot'
  },
  '/airbus': {
    description: "Unlock the potential of Airbus aircraft with MH Cockpit. Our specialized training programs cater to Airbus pilots, providing the skills and knowledge necessary for safe and efficient operation. Trust MH Cockpit for your Airbus aviation journey.",
    keywords: 'Airbus A320 EFIS display system | Airbus A320 avionics suppliers | Airbus Product | Airbus avionics systems | A320 replacement components supplier | Airbus A320 avionics suppliers | A320 throttle quadrant replacement parts | Airbus A320 throttle quadrant for simulators | A320 instrument panel replacement | Airbus A320 simulator panel kits',
    ogTitle: 'Explore the Latest Airbus A320 EFIS Display System Technology'
  },
  '/boeing': {
    description: "Take to the skies with confidence in Boeing aircraft, supported by MH Cockpit's expertise. Our tailored training programs are designed to empower Boeing pilots with the skills and knowledge required for successful flights. Partner with MH Cockpit for Boeing excellence.",
    keywords: 'Boeing B737 EFIS panel features | B737 EFIS replacement components | Boeing B737 avionics panel supplier | EFIS training for Boeing B737 | Boeing spare parts supplier | Boeing maintenance services',
    ogTitle: 'Gain Expert-Level EFIS Training for Boeing B737 Pilots'
  },
  '/mhintellect': {
    description: "Experience the power of MH Cockpit's aviation intelligence solutions. From data analytics to predictive modeling, MH Intellect provides cutting-edge insights to optimize aviation operations. Harness the intelligence of MH Cockpit for smarter aviation decision-making.",
    keywords: '',
    ogTitle: ''
  },
  '/contactus': {
    description: "Connect with MH Cockpit for all your aviation needs. Whether you're seeking training, information, or assistance, our dedicated team is here to help. Reach out to MH Cockpit and let us support your aviation journey.",
    keywords: '',
    ogTitle: ''
  },
  '/whatdowedo': {
    description: "Discover the breadth of services offered by MH Cockpit. From training to consulting, we specialize in all aspects of aviation excellence. Explore how MH Cockpit can support your aviation goals today.",
    keywords: '',
    ogTitle: ''
  },
  '/gallery': {
    description: "Immerse yourself in the world of aviation with MH Cockpit's gallery. Explore captivating images showcasing our training facilities, aircraft, and aviation events. Experience the excitement of aviation through MH Cockpit's gallery.",
    keywords: '',
    ogTitle: ''
  },
  '/pilotgroundinstructor': {
    description: "Become a certified pilot ground instructor with MH Cockpit. Our comprehensive training programs prepare you to educate and mentor aspiring pilots, shaping the future of aviation. Start your journey towards becoming a pilot ground instructor with MH Cockpit.",
    keywords: 'Pilot Ground Instructor | Pilot Ground Instructor Courses in Chennai | Pilot Ground Instructor Classes | Aviation Ground Instructor Training Institutes | Pilot Ground Instructor Training in Tamil Nadu | Ground Instructor Courses with Job Placement | Best Pilot Ground Instructor Training | Best Pilot Ground Instructor Training in India',
    ogTitle: 'Expert Pilot Ground Instructor Training with Job Placement in India'
  },
  '/ameinstructor': {
    description: "Elevate your career as an aircraft maintenance engineer (AME) instructor with MH Cockpit. Our specialized training equips you with the skills and knowledge needed to train the next generation of AMEs. Join MH Cockpit and inspire future aviation professionals as an AME instructor.",
    keywords: '',
    ogTitle: ''
  },
  '/dronepilots': {
    description: "Join the future of aviation as a certified drone pilot with MH Cockpit. Our comprehensive training programs cover all aspects of drone operation and regulation compliance. Launch your career in the exciting field of drone piloting with MH Cockpit.",
    keywords: '',
    ogTitle: ''
  },
  '/airportgroundoperationspersonnel': {
    description: "Prepare for a dynamic career in airport ground operations with MH Cockpit. Our specialized training programs cover essential skills and knowledge required for various roles in airport operations. Start your journey towards becoming a vital part of airport ground operations with MH Cockpit.",
    keywords: '',
    ogTitle: ''
  },
  '/cabincrewinstructor': {
    description: "Inspire the next generation of cabin crew members as a certified cabin crew instructor with MH Cockpit. Our comprehensive training programs provide you with the skills and knowledge needed to train aspiring cabin crew professionals. Join MH Cockpit and shape the future of cabin crew training.",
    keywords: '',
    ogTitle: ''
  },
  '/flightdispatch': {
    description: "Master the art of flight dispatch with MH Cockpit's specialized training programs. From flight planning to operational control, our comprehensive curriculum prepares you for a rewarding career in flight dispatch. Start your journey towards becoming a certified flight dispatcher with MH Cockpit.",
    keywords: '',
    ogTitle: ''
  },
  '/it': {
    description: "Explore the intersection of aviation and technology with MH Cockpit's IT solutions. From software development to network security, we provide cutting-edge IT services tailored to the aviation industry. Partner with MH Cockpit for innovative IT solutions in aviation.",
    keywords: '',
    ogTitle: ''
  },
  '/bdm': {
    description: "Empower your aviation business with MH Cockpit's business development and management services. From strategy to execution, we offer comprehensive solutions to help your business thrive in the competitive aviation industry. Partner with MH Cockpit for business growth and success.",
    keywords: '',
    ogTitle: ''
  },
  '/finance': {
    description: "Optimize your aviation finances with MH Cockpit's specialized financial services. From budgeting to investment strategies, our expert team provides tailored financial solutions to support your aviation ventures. Partner with MH Cockpit for financial excellence in aviation.",
    keywords: '',
    ogTitle: ''
  },
  '/labestablishment': {
    description: "Establish cutting-edge aviation training facilities with MH Cockpit's lab establishment services. From design to implementation, we provide comprehensive solutions to create state-of-the-art aviation training labs. Partner with MH Cockpit and set the standard for aviation training excellence.",
    keywords: 'Best Lab Establishment Firms Chennai | Lab Establishment Services | Commercial Lab Establishment in Chennai | Best Practices for Lab Establishment in Tamil Nadu | High-Tech Labs in India',
    ogTitle: ''
  },
  '/ourvalues': {
    description: "Discover the core values that drive MH Cockpit's commitment to excellence in aviation. From integrity to innovation, our values guide every aspect of our operations and service delivery. Partner with MH Cockpit and experience the difference our values make.",
    keywords: '',
    ogTitle: ''
  },
  '/colleges': {
    description: "Partner with MH Cockpit to shape the future of aviation education. Our collaboration opportunities for colleges and educational institutions offer access to cutting-edge aviation training programs and resources. Join forces with MH Cockpit to empower the next generation of aviation professionals.",
    keywords: '',
    ogTitle: ''
  },
  '/vels-university': {
    description: "Explore aviation education at Vels University with MH Cockpit. Discover specialized programs designed to prepare students for dynamic careers in the aviation industry. Partner with MH Cockpit and Vels University for excellence in aviation education.",
    keywords: 'Vels aviation courses |   Vels aviation fee structure | Cabin crew diploma Chennai | Aviation courses with placements Chennai | Best aviation colleges in Tamil Nadu | Top aviation training programs Chennai',
    ogTitle: 'Launch Your Career with Vels Aviation Courses & Cabin Crew Diploma in Chennai'
  },
  '/https://mru.univirt.in/': {
    description: "Unlock opportunities in aviation education at Malla Reddy University with MH Cockpit. Experience comprehensive training programs tailored to the needs of aspiring aviation professionals. Join MH Cockpit and Malla Reddy University for a transformative aviation education journey.",
    keywords: 'Malla Reddy College | Malla Reddy College Hyderabad | Best engineering colleges in Hyderabad | Top-ranked colleges in Hyderabad | Malla Reddy College fees | Courses offered by Malla Reddy College',
    ogTitle: 'Explore Malla Reddy College – Top Engineering College in Hyderabad'
  },
  '/jamal-mohamed-college': {
    description: "Embark on a journey of aviation excellence at Jamal Mohamed University with MH Cockpit. Explore innovative aviation training programs designed to nurture the talents of future aviation leaders. Partner with MH Cockpit and Jamal Mohamed University for a rewarding aviation education experience.",
    keywords: 'Jamal Mohamed College | Jamal Mohamed College admission | Jamal Mohamed College fees | Jamal Mohamed College courses | Jamal Mohamed College ranking | Jamal Mohamed College campus | Jamal Mohamed College placements | Jamal Mohamed College Tiruchirappalli | Jamal Mohamed College BBA',
    ogTitle: 'Explore Jamal Mohamed College: Courses, Fees, Admission & Placements'
  },
  '/crescent-landing': {
    description: "Discover Crescent College Chennai, Tamil Nadu’s hub for premier aviation training and top-tier courses. Start your journey with expert-led programs today.",
    keywords: 'Crescent College Chennai | Crescent College courses | Crescent aviation college | Crescent College Tamil Nadu | Crescent aviation training | Crescent College aviation courses | Crescent College application process | Crescent aviation college courses | Best Crescent College courses',
    ogTitle: 'Crescent College Chennai | Top Aviation Training & Courses in Tamil Nadu'
  },
  '/thank_you': {
    description: "Thank you for engaging with MH Cockpit. We appreciate your interest in our aviation training programs and services. Stay connected with us for updates and insights into the exciting world of aviation.",
    keywords: '',
    ogTitle: ''
  },
  '/privacy-policy': {
    description: "MH Cockpit is committed to protecting your privacy. Read our privacy policy to understand how we collect, use, and protect your personal information. Trust MH Cockpit for transparent and secure handling of your data.",
    keywords: '',
    ogTitle: ''
  },
  '/terms-and-conditions': {
    description: "Review the terms and conditions governing the use of MH Cockpit's website and services. By accessing our website or engaging with our services, you agree to abide by these terms and conditions. Trust MH Cockpit for clear and fair terms of use.",
    keywords: '',
    ogTitle: ''
  },

};

export default metaDescriptions;

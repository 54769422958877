// import { useEffect } from 'react';
// import metaDescriptions from './metaDescriptions'; // Import the mapping

// const MetaDescription = ({ pageLink }) => {
//   useEffect(() => {
//     const description = metaDescriptions[pageLink] || "Default meta description if not found";

//     const metaDescriptionTag = document.querySelector('meta[name="description"]');
//     if (metaDescriptionTag) {
//       metaDescriptionTag.setAttribute('content', description);
//     } else {
//       const newMetaTag = document.createElement('meta');
//       newMetaTag.setAttribute('name', 'description');
//       newMetaTag.setAttribute('content', description);
//       document.head.appendChild(newMetaTag);
//     }
//   }, [pageLink]);

//   return null;
// };

// export default MetaDescription;


import { useEffect } from 'react';
import metaDescriptions from './metaDescriptions'; // Import the mapping

const MetaDescriptionAndKeywords = ({ pageLink }) => {
  useEffect(() => {
    // Get the description, keywords, and ogTitle based on the page link
    const { description, keywords, ogTitle } = metaDescriptions[pageLink] || {
      description: "MH Cockpit, the top aviation college in Chennai and Tamil Nadu, specializes in advanced aviation training that sets students on the path to success. With an emphasis on industry-standard education and hands-on experience, expert instructors, and state-of-the-art facilities, we offer comprehensive programs for aspiring pilots and aviation professionals, laying the foundation for successful careers in the skies.",
      keywords: "aviation, training",
      ogTitle: "MH Cockpit - Aviation Training"
    };

    // Update the meta description tag
    let metaDescriptionTag = document.querySelector('meta[name="description"]');
    if (metaDescriptionTag) {
      metaDescriptionTag.setAttribute('content', description);
    } else {
      const newMetaDescriptionTag = document.createElement('meta');
      newMetaDescriptionTag.setAttribute('name', 'description');
      newMetaDescriptionTag.setAttribute('content', description);
      document.head.appendChild(newMetaDescriptionTag);
    }

    // Update the meta keywords tag
    let metaKeywordsTag = document.querySelector('meta[name="keywords"]');
    if (metaKeywordsTag) {
      metaKeywordsTag.setAttribute('content', keywords);
    } else {
      const newMetaKeywordsTag = document.createElement('meta');
      newMetaKeywordsTag.setAttribute('name', 'keywords');
      newMetaKeywordsTag.setAttribute('content', keywords);
      document.head.appendChild(newMetaKeywordsTag);
    }

    // Update the Open Graph og:title tag
    let metaOgTitleTag = document.querySelector('meta[property="og:title"]');
    if (metaOgTitleTag) {
      metaOgTitleTag.setAttribute('content', ogTitle);
    } else {
      const newMetaOgTitleTag = document.createElement('meta');
      newMetaOgTitleTag.setAttribute('property', 'og:title');
      newMetaOgTitleTag.setAttribute('content', ogTitle);
      document.head.appendChild(newMetaOgTitleTag);
    }

  }, [pageLink]);

  return null;
};

export default MetaDescriptionAndKeywords;

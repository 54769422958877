import React, { useEffect, useRef } from 'react'
import FadeInSectionDownToUp from '../../../Components/FadeAnimations/FadeInSectionDownToUp';
// import { useNavigate } from 'react-router-dom';

export default function DiplomaInAirportManagement() {

    const videoRef = useRef(null);

    useEffect(() => {
        const video = videoRef.current;

        if (video) {
            const playPromise = video.play();

            if (playPromise !== undefined) {
                playPromise
                    .then(_ => {
                        // Autoplay started
                        console.log('Autoplay started');
                    })
                    .catch(error => {
                        // Autoplay was prevented, unmute and play
                        video.muted = true; // Unmute the video
                        video.play().catch(error => {
                            console.error('Autoplay was blocked:', error);
                        });
                    });
            }
        }
    }, []);

    // const navigate = useNavigate();

    const colleges = [

        {
            src: "https://ik.imagekit.io/mhcockpit1/Colleges/VELS.webp",
            title: "Vels University.",
            location: "Chennai.",
            ugc: "UGC Recognised",
            courses: "Diploma in Airport Management",
            button: "Apply Now",
            link: "/vels-university"
        },

    ]

    return (
        <div>
            <div>
                <video autoPlay loop playsInline className='w-full'>
                    <source src={"https://mh-cockpit.blr1.cdn.digitaloceanspaces.com/PG%20Diploma%20in%20AME.mp4"} type='video/mp4' />
                </video>
            </div>
            <div className='px-20 pb-20 xl:px-20 lg:px-20 max-sm:px-5 sm:px-5'>
                <div className='w-full bg-white -mt-20 xl:-mt-56 max-sm:-mt-10 py-20 xl:py-20 max-sm:py-10 sm:py-10 px-20 xl:px-20 max-sm:px-5 sm:px-5 rounded-3xl shadow-2xl shadow-gray-700 relative'>
                    <FadeInSectionDownToUp>
                        <h1 className='text-5xl max-sm:text-3xl text-center font-semibold'>Diploma in <span className='text-[#06a263]'>Airport Management.</span></h1>
                    </FadeInSectionDownToUp>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='text-lg text-justify pt-20 max-sm:pt-10'>
                                Airport and airline operations encompass the day-to-day activities involved in managing the flow of passengers, cargo, and aircraft within an airport or airline network. These operations include tasks such as check-in, security screening, baggage handling, boarding, flight dispatching, and aircraft maintenance. Airport operations also involve managing facilities such as runways, taxiways, terminals, gates, and ground support services. On the other hand, airline operations focus on coordinating flight schedules, crew scheduling, aircraft maintenance, fuel management, and ensuring compliance with regulatory requirements. Effective management of airport and airline operations is crucial for ensuring the safety, efficiency, and profitability of air travel.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='flex xl:flex-row lg:flex-row max-sm:flex-col sm:flex-col pt-14 gap-2'>
                        <div className='bg-black text-white w-1/4 lg:w-1/2 xl:w-1/4 max-sm:w-full sm:w-full flex justify-center items-center py-7 px-10 rounded-3xl'>
                            <FadeInSectionDownToUp>
                                <p className='text-[#06a263] font-bold text-xl pb-3'>Duration</p>
                                <p className='text-2xl'>1 Year</p>
                            </FadeInSectionDownToUp>
                        </div>
                        <div className='bg-black text-white w-3/4 lg:w-3/4 xl:w-3/4 max-sm:w-full sm:w-full flex justify-center items-center py-7 px-10 max-sm:px-5 rounded-3xl'>
                            <FadeInSectionDownToUp>
                                <p className='text-[#06a263] font-bold text-xl pb-3'>Eligibility</p>
                                <ul>
                                    <div className='flex'>
                                        <span className='text-xl text-[#06a263] font-bold mr-2'>&#9992;</span><li className=''>Graduate in any engineering degree or science degree (or) equivalent from a recognised board.</li>
                                    </div>
                                </ul>
                            </FadeInSectionDownToUp>
                        </div>
                    </div>
                    <div className='bg-black text-white flex justify-start items-center py-7 px-10 max-sm:px-5 rounded-3xl mt-2 pl-20 max-sm:pl-5'>
                        <FadeInSectionDownToUp>
                            <p className='text-[#06a263] font-bold text-xl pb-3'>Practical Exposure</p>
                            <ul>
                                <div className='flex'>
                                    <span className='text-xl text-[#06a263] font-bold mr-2'>&#9992;</span><li className=''>Airport Familiarization.</li>
                                </div>
                                <div className='flex'>
                                    <span className='text-xl text-[#06a263] font-bold mr-2'>&#9992;</span><li className=''>Flight Dispatch and Operations.</li>
                                </div>
                                <div className='flex'>
                                    <span className='text-xl text-[#06a263] font-bold mr-2'>&#9992;</span><li className=''>Airport Security and Safety.</li>
                                </div>
                                <div className='flex'>
                                    <span className='text-xl text-[#06a263] font-bold mr-2'>&#9992;</span><li className=''>Airline Customer Service.</li>
                                </div>
                                <div className='flex'>
                                    <span className='text-xl text-[#06a263] font-bold mr-2'>&#9992;</span><li className=''>Baggage Handling and Ramp Operations.</li>
                                </div>
                                <div className='flex'>
                                    <span className='text-xl text-[#06a263] font-bold mr-2'>&#9992;</span><li className=''>Airline Operations Management.</li>
                                </div>
                                <div className='flex'>
                                    <span className='text-xl text-[#06a263] font-bold mr-2'>&#9992;</span><li className=''>Regulatory Compliance and International Standards.</li>
                                </div>
                            </ul>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='py-10'>
                        {/* <FadeInSectionDownToUp>
                            <a href='/private-pilot-license' className='text-[#06a263] underline text-3xl'>Course Content</a>
                            </FadeInSectionDownToUp> */}
                        <FadeInSectionDownToUp>
                            <p className='text-3xl text-center font-semibold'>Colleges</p>
                        </FadeInSectionDownToUp>
                        <div className='flex justify-center items-center pt-5'>
                            <div className='grid grid-cols-1 max-sm:grid-cols-1 gap-5 w-1/4 max-sm:w-full'>
                                {colleges.map((card, index) => (
                                    <div key={index} className='rounded-xl shadow-xl hover:scale-105 transition-all ease-linear duration-300'>
                                        <a href={card.link}>
                                            <img className='rounded-t-xl' src={card.src} alt={card.title} />
                                            <div className='px-5 py-5'>
                                                <p className='font-semibold'>{card.title}</p>
                                                <div className='py-2'>
                                                    <p>{card.location}📍</p>
                                                    <p className='text-gray-400'>({card.ugc})</p>
                                                </div>
                                                <p>{card.courses}</p>
                                                <div className='flex justify-center items-center py-2'>
                                                    <button className='bg-gray-400 hover:bg-gray-300 transition-all ease-linear duration-300 px-5 py-1 rounded-md'>
                                                        <a href={card.link}>
                                                            {card.button}
                                                        </a>
                                                    </button>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/* <FadeInSectionDownToUp>
                        <div className='grid grid-cols-2 gap-2 justify-center items-center px-96 max-sm:px-0 sm:px-40'>
                            <button onClick={() => navigate('/contact')} className='bg-[#06a263] text-white py-2 rounded-xl mx-2'>For Queries</button>
                            <button onClick={() => navigate('/contact')} className='bg-[#06a263] text-white py-2 rounded-xl mx-2'>Apply Now</button>
                        </div>
                    </FadeInSectionDownToUp> */}
                    {/* <FadeInSectionDownToUp>
                        <div className='flex justify-center items-center'>
                            <button onClick={() => navigate('/contact')} className='bg-[#06a263] text-white text-xl px-3 py-2 rounded-xl hover:scale-105 transition-all ease-linear duration-300'>
                                Download Broucher
                            </button>
                        </div>
                    </FadeInSectionDownToUp> */}
                </div>
            </div>
        </div>
    )
}

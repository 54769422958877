import React, { useState } from 'react'
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp'
import { CountryCode } from '../../Components/Contact/CountryCode'
import axios from 'axios';

export default function Contact() {

    const [name, setName] = useState('');
    const [countrycode, setCountrycode] = useState('');
    const [number, setNumber] = useState('');
    const [mail, setMail] = useState('');
    const [qualification, setQualification] = useState('');
    const [state, setState] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [lookingfor, setLookingfor] = useState('');
    const [message, setMessage] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const addData = async () => {
        // Check if any required field is empty
        const requiredFields = [name, number, countrycode, mail, qualification, state, zipcode, lookingfor];
        const hasEmptyFields = requiredFields.some(field => field === '');
        setIsButtonDisabled(true);

        if (hasEmptyFields) {
            alert('Please fill in all required fields.');
            return;
        }

        try {
            const newData = {
                Name: name,
                Country_code: countrycode,
                Mobile_Number: number,
                Email: mail,
                Qualification: qualification,
                State: state,
                Zip_code: zipcode,
                Looking_For: lookingfor,
                Message_Box: message
            };
            await axios.post('https://sheetdb.io/api/v1/5elucfkippn6o', newData);
            setName('');
            setCountrycode('');
            setNumber('');
            setMail('');
            setQualification('');
            setState('');
            setZipcode('');
            setLookingfor('');
            setMessage('');
            // alert("Your form has been submitted successfully! Our team will contact you within 24 hours!");
        } catch (error) {
            console.error('Error in Submission', error);
        }
        // window.location.href = '/thank_you';
        // window.location.href = '/contact';
    };

    return (
        <div className='grid 2xl:grid-cols-3 max-2xl:grid-cols-3 xl:grid-cols max-xl:grid-cols-3 lg:grid-cols-3 max-lg:grid-cols-3 md:grid-cols-2 max-md:grid-cols-2 sm:grid-cols-1 max-sm:grid-cols-1 py-5 px-20 xl:py-5 xl:px-20 sm:px-10 sm:pt-20 max-sm:px-10'>
            <div className='flex-col justify-start items-center sm:justify-start sm:items-start max-sm:justify-start max-sm:items-start'>
                <FadeInSectionDownToUp>
                    <p className='font-semibold text-4xl pb-5 underline underline-offset-8'>Our Locations</p>
                </FadeInSectionDownToUp>
                <FadeInSectionDownToUp>
                    <div className='py-3'>
                        <p className='text-4xl py-2'>MH Cockpit - KSA</p>
                        <p>
                            Building No: 8045 Al Faisaliyah District:<br />
                            2276 Al Madinah Al Munawarah Road Jeddah<br />
                            Kingdom of Saudi Arabia
                        </p>
                        <p className='py-3 font-bold'>7305952831</p>
                    </div>
                </FadeInSectionDownToUp>
                <FadeInSectionDownToUp>
                    <div className='py-3'>
                        <p className='text-4xl py-2'>MH Cockpit - IND</p>
                        <p>
                            Featherlite building, 200 Feet Radial Rd,<br />
                            Iswarya Nagar, Raja J Joseph Colony<br />
                            Pallavaram, Chennai - 600043.
                        </p>
                        <p className='py-3 font-bold'>+91 7305952831</p>
                    </div>
                </FadeInSectionDownToUp>
                <FadeInSectionDownToUp>
                    <div className='py-3'>
                        <p className='text-4xl py-2'>MH Cockpit - DUBAI</p>
                        <p>
                            Al faisaliyah district 2276<br />
                            Al khorayef commercial center<br />
                            Al madinah road<br />
                            Building no 8045<br />
                            Jeddah.
                        </p>
                    </div>
                </FadeInSectionDownToUp>
                <FadeInSectionDownToUp>
                    <div className='py-3'>
                        <p className='text-4xl py-2'>MH Cockpit -UAE</p>
                        <p className='py-3 font-bold'>infouae@mhcockpit.com</p>
                    </div>
                </FadeInSectionDownToUp>
            </div>
            <div className='flex-col justify-center items-center sm:justify-start sm:items-start max-sm:justify-start max-sm:items-start'>
                <FadeInSectionDownToUp>
                    <p className='font-semibold text-4xl pb-5 underline underline-offset-8'>Our Global FTO's</p>
                </FadeInSectionDownToUp>
                <FadeInSectionDownToUp>
                    <div className='py-2'>
                        <p className='text-4xl py-2'>MH Cockpit - IND</p>
                        <p className='py-3 font-bold'>infoind@mhcockpit.com</p>
                    </div>
                </FadeInSectionDownToUp>
                <FadeInSectionDownToUp>
                    <div className='py-2'>
                        <p className='text-4xl py-2'>MH Cockpit - NZ</p>
                        <p className='py-3 font-bold'>infonz@mhcockpit.com</p>
                    </div>
                </FadeInSectionDownToUp>
                <FadeInSectionDownToUp>
                    <div className='py-2'>
                        <p className='text-4xl py-2'>MH Cockpit - UK</p>
                        <p className='py-3 font-bold'>infouk@mhcockpit.com</p>
                    </div>
                </FadeInSectionDownToUp>
                <FadeInSectionDownToUp>
                    <div className='py-2'>
                        <p className='text-4xl py-2'>MH Cockpit - AUS</p>
                        <p className='py-3 font-bold'>infoaus@mhcockpit.com</p>
                    </div>
                </FadeInSectionDownToUp>
                <FadeInSectionDownToUp>
                    <div className='py-2'>
                        <p className='text-4xl py-2'>MH Cockpit - GRC</p>
                        <p className='py-3 font-bold'>infogr@mhcockpit.com</p>
                    </div>
                </FadeInSectionDownToUp>
            </div>
            <div className='flex-col justify-end items-center sm:justify-start sm:items-start max-sm:justify-start max-sm:items-start'>
                <FadeInSectionDownToUp>
                    <div className='py-3'>
                        <input type='text' placeholder='Name' className='w-full px-3 py-2 rounded-md border border-gray-500' value={name} onChange={(e) => setName(e.target.value)} />
                    </div>
                    <div className='flex space-x-3 py-3'>
                        <select className='w-1/4 rounded-md border border-gray-500 py-2 text-center' value={countrycode} onChange={(e) => setCountrycode(e.target.value)}>
                            {CountryCode.map(code => (
                                <option
                                    key={code.value}
                                    value={code.value}
                                    disabled={code.disabled}
                                    selected={code.selected}
                                    hidden={code.hidden}
                                    className='text-start'
                                >
                                    {code.value || code.text}
                                </option>
                            ))}
                        </select>
                        <input type='text' placeholder='Mobile Number' className='w-full px-3 py-2 rounded-md border border-gray-500' value={number} onChange={(e) => setNumber(e.target.value)} />
                    </div>
                    <div className='py-3'>
                        <input type='text' placeholder='Email ID' className='w-full px-3 py-2 rounded-md border border-gray-500' value={mail} onChange={(e) => setMail(e.target.value)} />
                    </div>
                    <div className='py-3'>
                        <input type='text' placeholder='Qualification' className='w-full px-3 py-2 rounded-md border border-gray-500' value={qualification} onChange={(e) => setQualification(e.target.value)} />
                    </div>
                    <div className='py-3'>
                        <input type='text' placeholder='State' className='w-full px-3 py-2 rounded-md border border-gray-500' value={state} onChange={(e) => setState(e.target.value)} />
                    </div>
                    <div className='py-3'>
                        <input type='text' placeholder='Zip Code' className='w-full px-3 py-2 rounded-md border border-gray-500' value={zipcode} onChange={(e) => setZipcode(e.target.value)} />
                    </div>
                    <div className='py-3'>
                        <select className='w-full rounded-md border border-gray-500 py-2 px-3 text-gray-400' value={lookingfor} onChange={(e) => setLookingfor(e.target.value)}>
                            <option value="" disabled selected hidden>Looking For</option>
                            <option className='text-black'>Comercial Pilot License</option>
                            <option className='text-black'>Private Pilot License</option>
                            <option className='text-black'>Type Rating</option>
                            <option className='text-black'>DGCA Ground Class</option>
                            <option className='text-black'>BBA Aviation Management</option>
                            <option className='text-black'>BSc. Aviation</option>
                            <option className='text-black'>BSc. Aeroautical Science</option>
                            <option className='text-black'>BSc. Aircraft Maintenance Science</option>
                            <option className='text-black'>BBA Logistics & Supply Chain Management</option>
                            <option className='text-black'>MBA Aviation Management</option>
                            <option className='text-black'>MBA Logistics & Supply Chain Management</option>
                            <option className='text-black'>MBA HR Marketing and Finance</option>
                            <option className='text-black'>Agriculture Drones</option>
                            <option className='text-black'>DGCA License Conversion</option>
                            <option className='text-black'>Cabin Crew Training</option>
                        </select>
                    </div>
                    <div className='py-3'>
                        <input type='text' placeholder='Message Box (optional)' className='w-full px-3 py-2 rounded-md border border-gray-500' value={message} onChange={(e) => setMessage(e.target.value)} />
                    </div>
                    <center className='py-3'>
                        <button onClick={addData} disabled={isButtonDisabled} className='bg-gray-200 hover:bg-gray-300 transition duration-500 ease-in-out shadow-lg shadow-gray-400 py-2 px-8 rounded-md'>
                            Submit
                        </button>
                    </center>
                </FadeInSectionDownToUp>
            </div>
        </div>
    )
}

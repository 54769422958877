// import React, { useState } from 'react'
// import FadeInSectionDownToUp from '../../../Components/FadeAnimations/FadeInSectionDownToUp'
// import axios from 'axios';
// import { CountryCode } from '../../../Components/Contact/CountryCode';

// export default function VelsForm() {

//   const [name, setName] = useState('');
//   const [countrycode, setCountrycode] = useState('');
//   const [number, setNumber] = useState('');
//   const [mail, setMail] = useState('');
//   const [qualification, setQualification] = useState('');
//   const [state, setState] = useState('');
//   const [zipcode, setZipcode] = useState('');
//   const [lookingfor, setLookingfor] = useState('');
//   const [message, setMessage] = useState('');
//   const [isButtonDisabled, setIsButtonDisabled] = useState(false);

//   const addData = async () => {
//     // Check if any required field is empty
//     const requiredFields = [name, number, countrycode, mail, qualification, state, zipcode, lookingfor];
//     const hasEmptyFields = requiredFields.some(field => field === '');
//     setIsButtonDisabled(true);

//     if (hasEmptyFields) {
//       alert('Please fill in all required fields.');
//       return;
//     }

//     try {
//       const newData = {
//         Name: name,
//         Country_code: countrycode,
//         Mobile_Number: number,
//         Email: mail,
//         Qualification: qualification,
//         State: state,
//         Zip_code: zipcode,
//         Looking_For: lookingfor,
//         Message_Box: message
//       };
//       await axios.post('https://sheetdb.io/api/v1/5elucfkippn6o', newData);
//       setName('');
//       setCountrycode('');
//       setNumber('');
//       setMail('');
//       setQualification('');
//       setState('');
//       setZipcode('');
//       setLookingfor('');
//       setMessage('');
//       // alert("Your form has been submitted successfully! Our team will contact you within 24 hours!");
//     } catch (error) {
//       console.error('Error in Submission', error);
//     }
//     // window.location.href = '/thank_you';
//     // window.location.href = '/contact';
//   };

//   return (
//     <div>
//       <div>
//         <div className="flex justify-center items-center py-10">
//           <div className="w-full sm:w-1/2 max-sm:w-full p-5 shadow-2xl rounded-3xl">
//             <div className='text-center font-semibold text-3xl'>
//               <FadeInSectionDownToUp>
//                 <h1>Admissions Open 2025</h1>
//               </FadeInSectionDownToUp>
//             </div>
//             <FadeInSectionDownToUp>
//               <FadeInSectionDownToUp>
//                 <div>
//                   <label className='block'>Name:<span className='text-red-600'>*</span></label>
//                   <input
//                     className={`bg-transparent border border-gray-500 focus:border-blue-500 focus:outline-none focus:border-2 rounded w-full py-2 px-3`}
//                     type='text'
//                     // name="name"
//                     // value={formData.name}
//                     // onChange={handleChange}
//                     placeholder='Your Full Name'
//                   />
//                   {/* {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>} */}
//                 </div>
//               </FadeInSectionDownToUp>
//               <FadeInSectionDownToUp>
//                 <div>
//                   <label className='block'>Mobile Number:<span className='text-red-600'>*</span></label>
//                   <div className='flex justify-center items-center gap-2'>
//                     <select
//                       className={`bg-transparent border border-gray-500 focus:border-blue-500 focus:outline-none focus:border-2 text-center rounded w-1/4 py-2`}
//                       name='countryCode'
//                     // value={formData.countryCode}
//                     // onChange={handleChange}
//                     >
//                       {CountryCode.map(code => (
//                         <option
//                           key={code.value}
//                           value={code.value}
//                           // disabled={code.disabled}
//                           selected={code.selected}
//                           hidden={code.hidden}
//                           className='text-start focus:border-blue-500 focus:outline-none focus:border-2'
//                         >
//                           {code.value || code.text}
//                         </option>
//                       ))}
//                     </select>
//                     <input
//                       className={`bg-transparent border border-gray-500 focus:border-blue-500 focus:outline-none focus:border-2 rounded w-full py-2 px-3`}
//                       type='number'
//                       placeholder='Mobile Number'
//                     // name='contact'
//                     // value={formData.contact}
//                     // onChange={handleChange}
//                     />
//                   </div>
//                   {/* {errors.contact && <p className='text-red-500 text-sm'>{errors.contact}</p>} */}
//                 </div>
//               </FadeInSectionDownToUp>
//               <FadeInSectionDownToUp>
//                 <div>
//                   <label className='block'>Alternavtive Mobile Number:<span className='text-red-600'>*</span></label>
//                   <div className='flex justify-center items-center gap-2'>
//                     <select
//                       className={`bg-transparent border border-gray-500 focus:border-blue-500 focus:outline-none focus:border-2 text-center rounded w-1/4 py-2`}
//                       name='countryCode'
//                     // value={formData.countryCode}
//                     // onChange={handleChange}
//                     >
//                       {CountryCode.map(code => (
//                         <option
//                           key={code.value}
//                           value={code.value}
//                           // disabled={code.disabled}
//                           selected={code.selected}
//                           hidden={code.hidden}
//                           className='text-start focus:border-blue-500 focus:outline-none focus:border-2'
//                         >
//                           {code.value || code.text}
//                         </option>
//                       ))}
//                     </select>
//                     <input
//                       className={`bg-transparent border border-gray-500 focus:border-blue-500 focus:outline-none focus:border-2 rounded w-full py-2 px-3`}
//                       type='number'
//                       placeholder='Alternavtive Mobile Number'
//                     // name='contact'
//                     // value={formData.contact}
//                     // onChange={handleChange}
//                     />
//                   </div>
//                   {/* {errors.contact && <p className='text-red-500 text-sm'>{errors.contact}</p>} */}
//                 </div>
//               </FadeInSectionDownToUp>
//               <div className="py-3">
//                 <input
//                   type="text"
//                   placeholder="Name"
//                   className="w-full px-3 py-2 rounded-md border border-gray-500"
//                   value={name}
//                   onChange={(e) => setName(e.target.value)}
//                 />
//               </div>
//               <div className="flex space-x-3 py-3">
//                 <select
//                   className="w-1/4 rounded-md border border-gray-500 py-2 text-center"
//                   value={countrycode}
//                   onChange={(e) => setCountrycode(e.target.value)}
//                 >
//                   {CountryCode.map(code => (
//                     <option
//                       key={code.value}
//                       value={code.value}
//                       disabled={code.disabled}
//                       selected={code.selected}
//                       hidden={code.hidden}
//                       className="text-start"
//                     >
//                       {code.value || code.text}
//                     </option>
//                   ))}
//                 </select>
//                 <input
//                   type="text"
//                   placeholder="Mobile Number"
//                   className="w-full px-3 py-2 rounded-md border border-gray-500"
//                   value={number}
//                   onChange={(e) => setNumber(e.target.value)}
//                 />
//               </div>
//               <div className="py-3">
//                 <input
//                   type="text"
//                   placeholder="Email ID"
//                   className="w-full px-3 py-2 rounded-md border border-gray-500"
//                   value={mail}
//                   onChange={(e) => setMail(e.target.value)}
//                 />
//               </div>
//               <div className="py-3">
//                 <input
//                   type="text"
//                   placeholder="Qualification"
//                   className="w-full px-3 py-2 rounded-md border border-gray-500"
//                   value={qualification}
//                   onChange={(e) => setQualification(e.target.value)}
//                 />
//               </div>
//               <div className="py-3">
//                 <input
//                   type="text"
//                   placeholder="State"
//                   className="w-full px-3 py-2 rounded-md border border-gray-500"
//                   value={state}
//                   onChange={(e) => setState(e.target.value)}
//                 />
//               </div>
//               <div className="py-3">
//                 <input
//                   type="text"
//                   placeholder="Zip Code"
//                   className="w-full px-3 py-2 rounded-md border border-gray-500"
//                   value={zipcode}
//                   onChange={(e) => setZipcode(e.target.value)}
//                 />
//               </div>
//               <div className="py-3">
//                 <select
//                   className="w-full rounded-md border border-gray-500 py-2 px-3 text-gray-400"
//                   value={lookingfor}
//                   onChange={(e) => setLookingfor(e.target.value)}
//                 >
//                   <option value="" disabled selected hidden>Looking For</option>
//                   <option className="text-black">Comercial Pilot License</option>
//                   <option className="text-black">Private Pilot License</option>
//                   <option className="text-black">Type Rating</option>
//                   <option className="text-black">DGCA Ground Class</option>
//                   <option className="text-black">BBA Aviation Management</option>
//                   <option className="text-black">BSc. Aviation</option>
//                   <option className="text-black">BSc. Aeroautical Science</option>
//                   <option className="text-black">BSc. Aircraft Maintenance Science</option>
//                   <option className="text-black">BBA Logistics & Supply Chain Management</option>
//                   <option className="text-black">MBA Aviation Management</option>
//                   <option className="text-black">MBA Logistics & Supply Chain Management</option>
//                   <option className="text-black">MBA HR Marketing and Finance</option>
//                   <option className="text-black">MH Cockpit</option>
//                   <option className="text-black">Agriculture Drones</option>
//                   <option className="text-black">DGCA License Conversion</option>
//                   <option className="text-black">Cabin Crew Training</option>
//                 </select>
//               </div>
//               <div className="py-3">
//                 <input
//                   type="text"
//                   placeholder="Message Box (optional)"
//                   className="w-full px-3 py-2 rounded-md border border-gray-500"
//                   value={message}
//                   onChange={(e) => setMessage(e.target.value)}
//                 />
//               </div>
//               <center className="py-3">
//                 <button
//                   onClick={addData}
//                   disabled={isButtonDisabled}
//                   className="bg-gray-200 hover:bg-gray-300 transition duration-500 ease-in-out shadow-lg shadow-gray-400 py-2 px-8 rounded-md"
//                 >
//                   Submit
//                 </button>
//               </center>
//             </FadeInSectionDownToUp>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }


import React, { useState } from "react";
import { CountryCode } from "../../../Components/Contact/CountryCode";
import axios from "axios";

const VelsForm = () => {
  // Form data state
  const [formData, setFormData] = useState({
    fullName: "",
    countryCode: "",
    mobileNumber: "",
    alternativeCountryCode: "",
    alternativeNumber: "",
    email: "",
    programName: "",
    location: "",
    aviationReason: "",
    expectations: "",
    knowledgeOfAviation: "",
    willingnessToRelocate: "",
    friendsInAviation: "",
  });

  // State to control form parts and progress
  const [currentPart, setCurrentPart] = useState(1);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleNext = () => {
    if (currentPart === 1 && validateFirstPart()) {
      if (formData.mobileNumber === formData.alternativeNumber) {
        alert("Mobile number and alternative mobile number cannot be the same.");
        return;
      }
      setCurrentPart(2);
    }
  };

  const handlePrevious = () => {
    setCurrentPart(1);
  };

  const validateFirstPart = () => {
    const { fullName, countryCode, mobileNumber, alternativeCountryCode, alternativeNumber, email, programName, location } = formData;
    if (!fullName || !countryCode || !mobileNumber || !alternativeCountryCode || !alternativeNumber || !email || !programName || !location) {
      alert("Please fill out all fields.");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { aviationReason, expectations, knowledgeOfAviation, willingnessToRelocate, friendsInAviation } = formData;
    if (!aviationReason || !expectations || !knowledgeOfAviation || !willingnessToRelocate || !friendsInAviation) {
      alert("Please fill out all fields.");
      return false;
    }
    try {
      const sendData = {
        Full_Name: formData.fullName,
        Country_Code: formData.countryCode,
        Mobile_Number: formData.mobileNumber,
        Alternative_Country_Code: formData.alternativeCountryCode,
        Alternative_Number: formData.alternativeNumber,
        Email: formData.email,
        Program_Name: formData.programName,
        Location: formData.location,
        Aviation_Reason: formData.aviationReason,
        Expectations: formData.expectations,
        Knowledge_Of_Aviation: formData.knowledgeOfAviation,
        Willingness: formData.willingnessToRelocate,
        Friends_In_Aviation: formData.friendsInAviation,
      }
      const response = await axios.post("https://sheetdb.io/api/v1/2e5nss8va55fz", sendData)
      console.log("Successfull", response);
      setFormData({
        fullName: "",
        countryCode: "",
        mobileNumber: "",
        alternativeCountryCode: "",
        alternativeNumber: "",
        email: "",
        programName: "",
        location: "",
        aviationReason: "",
        expectations: "",
        knowledgeOfAviation: "",
        willingnessToRelocate: "",
        friendsInAviation: "",
      })
      alert("Successfull")
    } catch (error) {
      console.error('Error Submission', error);
      alert("Error Submission")
    }

    console.log("Form submitted:", formData);
    // Here you can add form validation and API calls
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 max-sm:px-5">
      <div className="w-full max-w-lg bg-white p-8 max-sm:p-5 rounded-lg shadow-md">
        <h1 className="text-2xl font-bold text-center mb-4">Enquiry Form 2025</h1>
        {currentPart === 1 && (
          <form className="space-y-4">
            <div>
              <label htmlFor="fullName" className="block text-sm font-medium text-gray-700">
                Enter Full Name:<span className='text-red-600'>*</span>
              </label>
              <input
                type="text"
                placeholder="Enter Full Name"
                id="fullName"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 focus:border-blue-500 focus:outline-none rounded-md"
                required
              />
            </div>
            <div>
              <label className='block'>Mobile Number:<span className='text-red-600'>*</span></label>
              <div className='flex justify-center items-center gap-2'>
                <select
                  className={`bg-transparent border border-gray-300 focus:border-blue-500 focus:outline-none text-center rounded w-1/4 py-2`}
                  name='countryCode'
                  value={formData.countryCode}
                  onChange={handleChange}
                >
                  {CountryCode.map(code => (
                    <option
                      key={code.value}
                      value={code.value}
                      // disabled={code.disabled}
                      selected={code.selected}
                      hidden={code.hidden}
                      className='text-start'
                    >
                      {code.value || code.text}
                    </option>
                  ))}
                </select>
                <input
                  className={`bg-transparent border border-gray-300 focus:border-blue-500 focus:outline-none rounded w-full py-2 px-3`}
                  type='number'
                  placeholder='Enter Mobile Number'
                  name='mobileNumber'
                  value={formData.mobileNumber}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div>
              <label className='block'>Alternative Mobile Number:<span className='text-red-600'>*</span></label>
              <div className='flex justify-center items-center gap-2'>
                <select
                  className={`bg-transparent border border-gray-300 focus:border-blue-500 focus:outline-none text-center rounded w-1/4 py-2`}
                  name='alternativeCountryCode'
                  value={formData.alternativeCountryCode}
                  onChange={handleChange}
                >
                  {CountryCode.map(code => (
                    <option
                      key={code.value}
                      value={code.value}
                      // disabled={code.disabled}
                      selected={code.selected}
                      hidden={code.hidden}
                      className='text-start'
                    >
                      {code.value || code.text}
                    </option>
                  ))}
                </select>
                <input
                  className={`bg-transparent border border-gray-300 focus:border-blue-500 focus:outline-none rounded w-full py-2 px-3`}
                  type='number'
                  placeholder='Alternative Mobile Number'
                  name='alternativeNumber'
                  value={formData.alternativeNumber}
                  onChange={handleChange}
                />
              </div>
            </div>

            {/* Alternative Number */}
            {/* <div>
              <label htmlFor="alternativeNumber" className="block text-sm font-medium text-gray-700">
                Alternative Number
              </label>
              <input
                type="tel"
                id="alternativeNumber"
                name="alternativeNumber"
                value={formData.alternativeNumber}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 focus:border-blue-500 focus:outline-none rounded-md"
                pattern="^[0-9]{10}$"
              />
            </div> */}

            {/* Email */}
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Mail ID:<span className='text-red-600'>*</span>
              </label>
              <input
                type="email"
                placeholder="Enter Mail ID"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 focus:border-blue-500 focus:outline-none rounded-md"
                required
              />
            </div>

            {/* Program Name */}
            <div>
              <label htmlFor="programName" className="block text-sm font-medium text-gray-700">
                Select Program:<span className='text-red-600'>*</span>
              </label>
              <select
                id="programName"
                name="programName"
                value={formData.programName}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 focus:border-blue-500 focus:outline-none rounded-md"
                required
              >
                <option value="" disabled selected hidden>Select Program</option>
                <option>Comercial Pilot License</option>
                <option>Private Pilot License</option>
                <option>Type Rating</option>
                <option>DGCA Ground Class</option>
                <option>BBA Aviation Management</option>
                <option>BSc. Aviation</option>
                <option>BSc. Aeroautical Science</option>
                <option>B.Tech Aerospace Engineering</option>
                <option>BBA Aviation and Tourism Management</option>
                <option>BBA Logistics & Supply Chain Management</option>
                <option>MBA Aviation Management</option>
                <option>MBA Logistics & Supply Chain Management</option>
                <option>MBA HR Marketing and Finance</option>
                <option>DGCA License Conversion</option>
                <option>Cabin Crew Training</option>
                <option>PG Diploma in AME</option>
              </select>
            </div>

            {/* Location */}
            <div>
              <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                Location:<span className='text-red-600'>*</span>
              </label>
              <textarea
                type="text"
                placeholder="Your Address"
                id="location"
                name="location"
                value={formData.location}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 focus:border-blue-500 focus:outline-none rounded-md"
              />
            </div>

            {/* Next Button */}
            <button
              type="button"
              onClick={handleNext}
              className="w-full bg-blue-500 text-white py-2 rounded-md mt-4 hover:bg-blue-600"
            >
              Next
            </button>
          </form>
        )}

        {/* Second Part of the Form */}
        {currentPart === 2 && (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="aviationReason" className="block text-sm font-medium text-gray-700">
                Why do you want to choose Aviation?<span className='text-red-600'>*</span>
              </label>
              <textarea
                placeholder="Your Answer"
                id="aviationReason"
                name="aviationReason"
                value={formData.aviationReason}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 focus:border-blue-500 focus:outline-none rounded-md"
                required
              />
            </div>

            <div>
              <label htmlFor="expectations" className="block text-sm font-medium text-gray-700">
                What do you expect from Aviation?<span className='text-red-600'>*</span>
              </label>
              <textarea
                placeholder="Your Answer"
                id="expectations"
                name="expectations"
                value={formData.expectations}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 focus:border-blue-500 focus:outline-none rounded-md"
                required
              />
            </div>

            <div>
              <label htmlFor="knowledgeOfAviation" className="block text-sm font-medium text-gray-700">
                Tell me something about Aviation.<span className='text-red-600'>*</span>
              </label>
              <textarea
                placeholder="Your Answer"
                id="knowledgeOfAviation"
                name="knowledgeOfAviation"
                value={formData.knowledgeOfAviation}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 focus:border-blue-500 focus:outline-none rounded-md"
              />
            </div>

            <div>
              <label htmlFor="willingnessToRelocate" className="block text-sm font-medium text-gray-700">
                If you're outside of Chennai, are you willing to relocate?<span className='text-red-600'>*</span>
              </label>
              <textarea
                placeholder="Your Answer"
                id="willingnessToRelocate"
                name="willingnessToRelocate"
                value={formData.willingnessToRelocate}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 focus:border-blue-500 focus:outline-none rounded-md"
              />
            </div>

            <div>
              <label htmlFor="friendsInAviation" className="block text-sm font-medium text-gray-700">
                Do you know any friends or relatives working in aviation?<span className='text-red-600'>*</span>
              </label>
              <textarea
                placeholder="Your Answer"
                id="friendsInAviation"
                name="friendsInAviation"
                value={formData.friendsInAviation}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 focus:border-blue-500 focus:outline-none rounded-md"
              />
            </div>

            {/* Previous and Submit Button */}
            <div className="flex justify-between">
              <button
                type="button"
                onClick={handlePrevious}
                className="w-1/3 bg-gray-500 text-white py-2 rounded-md hover:bg-gray-600"
              >
                Previous
              </button>

              <button
                type="submit"
                className="w-1/3 bg-green-500 text-white py-2 rounded-md hover:bg-green-600"
              >
                Submit Form
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default VelsForm;


import React from 'react';

const GalleryModal = ({ isOpen, imageSrc, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
      <div className="relative bg-white p-5 max-sm:p-2 rounded-lg max-w-screen-lg mx-auto">
        <button
          onClick={onClose}
          className="absolute top-3 right-3 px-3 text-white max-sm:text-black bg-black max-sm:bg-white bg-opacity-50 rounded-full hover:bg-gray-700"
          aria-label="Close"
        >
          &times;
        </button>
        <img src={imageSrc} alt="Large View" className="w-full h-auto rounded-3xl max-sm:rounded-lg" />
      </div>
    </div>
  );
};

export default GalleryModal;

import React, { useEffect, useRef } from 'react'
import FadeInSectionDownToUp from '../../../Components/FadeAnimations/FadeInSectionDownToUp';
import { useNavigate } from 'react-router-dom';

export default function PPL() {

  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      const playPromise = video.play();

      if (playPromise !== undefined) {
        playPromise
          .then(_ => {
            // Autoplay started
            console.log('Autoplay started');
          })
          .catch(error => {
            // Autoplay was prevented, unmute and play
            video.muted = true; // Unmute the video
            video.play().catch(error => {
              console.error('Autoplay was blocked:', error);
            });
          });
      }
    }
  }, []);

  const navigate = useNavigate();

  return (
    <div>
      <div>
        <video autoPlay loop playsInline className='w-full'>
          <source src={"https://mh-cockpit.blr1.cdn.digitaloceanspaces.com/PPL.mp4"} type='video/mp4' />
        </video>
      </div>
      <div className='px-20 pb-20 xl:px-20 lg:px-20 max-sm:px-5 sm:px-5'>
        <div className='w-full bg-white -mt-20 xl:-mt-56 max-sm:-mt-10 py-20 xl:py-20 max-sm:py-10 sm:py-10 px-20 xl:px-20 max-sm:px-5 sm:px-5 rounded-3xl shadow-2xl shadow-gray-700 relative'>
          <FadeInSectionDownToUp>
            <h1 className='text-5xl max-sm:text-3xl text-center font-semibold'>Private Pilot <span className='text-blue-600'>License.</span></h1>
          </FadeInSectionDownToUp>
          <div>
            <FadeInSectionDownToUp>
              <p className='text-lg text-justify pt-20 max-sm:pt-10'>
                Unlock the skies with a Private Pilot License (PPL), your gateway to a thrilling aviation journey. PPL is your inaugural step, laying the groundwork for comprehensive aircraft pilot training. With a PPL, you gain the knowledge and skills to confidently navigate airplanes day and night under visual flight conditions. Revel in the freedom to take friends, family, and co-workers on your adventures, albeit with certain restrictions – no flying for paid compensation or hire. Embark on your aviation career journey with PPL, the foundational course paving the way to lucrative opportunities, including a rewarding path to an airline pilot career. Explore the possibilities of your pilot dreams with us.
              </p>
            </FadeInSectionDownToUp>
          </div>
          <div className='flex xl:flex-row lg:flex-row max-sm:flex-col sm:flex-col pt-14 gap-2'>
            <div className='bg-black text-white w-1/4 lg:w-1/2 xl:w-1/4 max-sm:w-full sm:w-full flex justify-center items-center py-7 px-10 rounded-3xl'>
              <FadeInSectionDownToUp>
                <p className='text-blue-600 font-bold text-xl pb-3'>Duration</p>
                <p className='text-2xl'>3 - 4 Months</p>
              </FadeInSectionDownToUp>
            </div>
            <div className='bg-black text-white w-3/4 lg:w-3/4 xl:w-3/4 max-sm:w-full sm:w-full flex justify-center items-center py-7 px-10 max-sm:px-5 rounded-3xl'>
              <FadeInSectionDownToUp>
                <p className='text-blue-600 font-bold text-xl pb-3'>Eligibility</p>
                <ul>
                  <div className='flex'>
                    <span className='text-xl text-blue-600 font-bold mr-2'>&#9992;</span><li className=''>Individual configuration.</li>
                  </div>
                  <div className='flex'>
                    <span className='text-xl text-blue-600 font-bold mr-2'>&#9992;</span><li className=''>10th class pass from a recognised board.</li>
                  </div>
                  <div className='flex'>
                    <span className='text-xl text-blue-600 font-bold mr-2'>&#9992;</span><li className=''>At least 16 years of age at the time of joining.</li>
                  </div>
                  <div className='flex'>
                    <span className='text-xl text-blue-600 font-bold mr-2'>&#9992;</span><li className=''>Adequate competency in the English Language.</li>
                  </div>
                </ul>
              </FadeInSectionDownToUp>
            </div>
          </div>
          <div className='pt-2'>
            <div className='bg-black text-white py-5 pl-20 max-sm:pl-5 flex justify-start items-center rounded-3xl'>
              <FadeInSectionDownToUp>
                <p className='text-blue-600 font-bold text-xl pb-3'>Flying Training</p>
                <ul>
                  <div className='flex'>
                    <span className='text-xl text-blue-600 font-bold mr-2'>&#9992;</span><li className=''>50 Hrs On a Single engine aircraft (C-172,DA40, Tecnam).</li>
                  </div>
                  <div className='flex'>
                    <span className='text-xl text-blue-600 font-bold mr-2'>&#9992;</span><li className=''>5 Hrs of Simulator Training.</li>
                  </div>
                </ul>
              </FadeInSectionDownToUp>
            </div>
          </div>
          <div className='pt-2'>
            <div className='bg-black text-white py-5 pl-20 max-sm:pl-5 flex justify-start items-center rounded-3xl'>
              <FadeInSectionDownToUp>
                <p className='text-blue-600 font-bold text-xl pb-3'>Theory</p>
                <ul>
                  <div className='flex'>
                    <span className='text-xl text-blue-600 font-bold mr-2'>&#9992;</span><li className=''>Air Regulation.</li>
                  </div>
                  <div className='flex'>
                    <span className='text-xl text-blue-600 font-bold mr-2'>&#9992;</span><li className=''>Air Navigation.</li>
                  </div>
                  <div className='flex'>
                    <span className='text-xl text-blue-600 font-bold mr-2'>&#9992;</span><li className=''>Aviation Meteorology.</li>
                  </div>
                  <div className='flex'>
                    <span className='text-xl text-blue-600 font-bold mr-2'>&#9992;</span><li className=''>Aircraft & Engines (General & Specific).</li>
                  </div>
                </ul>
              </FadeInSectionDownToUp>
            </div>
          </div>
          <div className='flex justify-center items-center py-10'>
            {/* <FadeInSectionDownToUp>
              <a href='/private-pilot-license' className='text-blue-600 underline text-3xl'>Course Content</a>
            </FadeInSectionDownToUp> */}
          </div>
          <FadeInSectionDownToUp>
            <div className='grid grid-cols-3 gap-2 justify-center items-center px-96 max-sm:px-0 sm:px-40'>
              <button onClick={() => navigate('/contact')} className='bg-blue-600 text-white py-2 rounded-xl mx-2'>For Queries</button>
              <button onClick={() => navigate('/contact')} className='bg-blue-600 text-white py-2 rounded-xl mx-2'>Apply Now</button>
              <button className='bg-blue-600 text-white py-2 rounded-xl mx-2'><a href='https://drive.google.com/file/d/1QBDX5O5E_0BJBEy8klu0Co1IPc8C-lJr/view?usp=drive_link' target='blank'>Downoad</a></button>
            </div>
          </FadeInSectionDownToUp>
        </div>
      </div>
    </div>
  )
}

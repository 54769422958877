import React, { useEffect, useRef } from 'react'
import FadeInSectionDownToUp from '../../../Components/FadeAnimations/FadeInSectionDownToUp';
// import { useNavigate } from 'react-router-dom';

export default function CabinCrew() {

    const videoRef = useRef(null);

    useEffect(() => {
        const video = videoRef.current;

        if (video) {
            const playPromise = video.play();

            if (playPromise !== undefined) {
                playPromise
                    .then(_ => {
                        // Autoplay started
                        console.log('Autoplay started');
                    })
                    .catch(error => {
                        // Autoplay was prevented, unmute and play
                        video.muted = true; // Unmute the video
                        video.play().catch(error => {
                            console.error('Autoplay was blocked:', error);
                        });
                    });
            }
        }
    }, []);

    // const navigate = useNavigate();
    
    const colleges = [

        {
            src: "https://ik.imagekit.io/mhcockpit1/Colleges/VELS.webp",
            title: "Vels University.",
            location: "Chennai.",
            ugc: "UGC Recognised",
            courses: "Diploma in Cabin Crew",
            button: "Apply Now",
            link: "/vels-university"
        },

    ]

    return (
        <div>
            <div>
                <video autoPlay loop playsInline className='w-full'>
                    <source src={"https://mh-cockpit.blr1.cdn.digitaloceanspaces.com/B.Sc%20Aeronautical%20Science.mp4"} type='video/mp4' />
                </video>
            </div>
            <div className='px-20 pb-20 xl:px-20 lg:px-20 max-sm:px-5 sm:px-5'>
                <div className='w-full bg-white -mt-20 xl:-mt-56 max-sm:-mt-10 py-20 xl:py-20 max-sm:py-10 sm:py-10 px-20 xl:px-20 max-sm:px-5 sm:px-5 rounded-3xl shadow-2xl shadow-gray-700 relative'>
                    <FadeInSectionDownToUp>
                        <h1 className='text-5xl max-sm:text-3xl text-center font-semibold'>Cabin <span className='text-[#ee8446]'>Crew.</span></h1>
                    </FadeInSectionDownToUp>
                    <div>
                        <FadeInSectionDownToUp>
                            <p className='text-lg text-justify pt-20 max-sm:pt-10'>
                                Working as cabin crew for a major airline is an exciting and challenging experience. In addition to jetting off to exotic destinations, the job also requires a high degree of responsibility and specialization to ensure the safety and comfort of passengers in line with industry regulations.
                            </p>
                            <p className='text-lg text-justify py-5 max-sm:py-5'>
                                This course is ideal for young professionals looking to get a head start in the profession, introducing the skills and responsibilities expected by the world’s leading airlines. Special emphasis is given to customer service and procedures for handling unusual situations during flight, with the final module of the course giving useful tips for completing the recruitment process. This course also includes a safety equipment procedure (SEP) trainer providing an overview of the aircraft, safety equipment and location of emergency procedures including aircraft technical training.
                            </p>
                        </FadeInSectionDownToUp>
                    </div>
                    <div className='flex xl:flex-row lg:flex-row max-sm:flex-col sm:flex-col pt-2 gap-2'>
                        <div className='bg-black text-white w-1/2 lg:w-1/2 xl:w-1/2 max-sm:w-full sm:w-full flex justify-center items-center py-7 px-10 max-sm:px-5 rounded-3xl'>
                            <FadeInSectionDownToUp>
                                <p className='text-[#ee8446] font-bold text-xl pb-3'>Training Modules.</p>
                                <ul>
                                    <div className='flex'>
                                        <span className='text-xl text-[#ee8446] font-bold mr-2'>&#9992;</span><li className=''>Introduction.</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-[#ee8446] font-bold mr-2'>&#9992;</span><li className=''>Language Proficiency.</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-[#ee8446] font-bold mr-2'>&#9992;</span><li className=''>Aviation Grooming & Personality Development.</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-[#ee8446] font-bold mr-2'>&#9992;</span><li className=''>Safety and Emergency Procedure.</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-[#ee8446] font-bold mr-2'>&#9992;</span><li className=''>Medical And First Aid.</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-[#ee8446] font-bold mr-2'>&#9992;</span><li className=''>Aviation Security.</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-[#ee8446] font-bold mr-2'>&#9992;</span><li className=''>Inflight service.</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-[#ee8446] font-bold mr-2'>&#9992;</span><li className=''>Aircraft Technical.</li>
                                    </div>
                                </ul>
                            </FadeInSectionDownToUp>
                        </div>
                        <div className='bg-black text-white w-1/2 lg:w-1/2 xl:w-1/2 max-sm:w-full sm:w-full flex justify-center items-center py-7 px-10 rounded-3xl'>
                            <FadeInSectionDownToUp>
                                <p className='text-[#ee8446] font-bold text-xl pb-3'>Eligibility.</p>
                                <ul>
                                    <div className='flex'>
                                        <span className='text-xl text-[#ee8446] font-bold mr-2'>&#9992;</span><li className=''>Female Indian nationals, with an Indian Passport, between 17 to 25 years of age.</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-[#ee8446] font-bold mr-2'>&#9992;</span><li className=''>Educational Qualification: A minimum of 10th, +2, from a recognised Board or University.</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-[#ee8446] font-bold mr-2'>&#9992;</span><li className=''>Communication: Fluency and clarity of speech in both English and Hindi.</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-[#ee8446] font-bold mr-2'>&#9992;</span><li className=''>Height and Weight: A minimum of 155 cms, and weight proportionate to BMI.</li>
                                    </div>
                                    <div className='flex'>
                                        <span className='text-xl text-[#ee8446] font-bold mr-2'>&#9992;</span><li className=''>Appearance: Positive body language and well groomed. No tattoos should be visible while in uniform.</li>
                                    </div>
                                </ul>
                            </FadeInSectionDownToUp>
                        </div>
                    </div>
                                        <div className='py-10'>
                        {/* <FadeInSectionDownToUp>
                            <a href='/private-pilot-license' className='text-[] underline text-3xl'>Course Content</a>
                            </FadeInSectionDownToUp> */}
                        <FadeInSectionDownToUp>
                            <p className='text-3xl text-center font-semibold'>Colleges</p>
                        </FadeInSectionDownToUp>
                        <div className='flex justify-center items-center pt-5'>
                            <div className='grid grid-cols-1 max-sm:grid-cols-1 gap-5 w-1/4 max-sm:w-full'>
                                {colleges.map((card, index) => (
                                    <div key={index} className='rounded-xl shadow-xl hover:scale-105 transition-all ease-linear duration-300'>
                                        <a href={card.link}>
                                            <img className='rounded-t-xl' src={card.src} alt={card.title} />
                                            <div className='px-5 py-5'>
                                                <p className='font-semibold'>{card.title}</p>
                                                <div className='py-2'>
                                                    <p>{card.location}📍</p>
                                                    <p className='text-gray-400'>({card.ugc})</p>
                                                </div>
                                                <p>{card.courses}</p>
                                                <div className='flex justify-center items-center py-2'>
                                                    <button className='bg-gray-400 hover:bg-gray-300 transition-all ease-linear duration-300 px-5 py-1 rounded-md'>
                                                        <a href={card.link}>
                                                            {card.button}
                                                        </a>
                                                    </button>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/* <FadeInSectionDownToUp>
                        <div className='grid grid-cols-2 gap-2 justify-center items-center px-96 max-sm:px-0 sm:px-40'>
                            <button onClick={() => navigate('/contact')} className='bg-[#6b8d36] text-white py-2 rounded-xl mx-2'>For Queries</button>
                            <button onClick={() => navigate('/contact')} className='bg-[#6b8d36] text-white py-2 rounded-xl mx-2'>Apply Now</button>
                        </div>
                    </FadeInSectionDownToUp> */}
                    {/* <FadeInSectionDownToUp>
                        <div className='flex justify-center items-center'>
                            <button onClick={() => navigate('/contact')} className='bg-[#6b8d36] text-white text-xl px-3 py-2 rounded-xl hover:scale-105 transition-all ease-linear duration-300'>
                                Download Broucher
                            </button>
                        </div>
                    </FadeInSectionDownToUp> */}
                </div>
            </div>
        </div>
    )
}

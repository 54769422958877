import React from 'react'
import FadeInSectionDownToUp from '../../../Components/FadeAnimations/FadeInSectionDownToUp'

export default function Blog8() {
    return (
        <div>
            <div className='xl:pt-0 sm:pt-12'>
                <FadeInSectionDownToUp>
                    <h1 className='text-center font-semibold text-3xl xl:text-3xl sm:text-xl max-sm:text-lg py-5'>Navigating the Skies: Understanding the Differences Between B.E. and B.Sc. in Aeronautical Engineering</h1>
                </FadeInSectionDownToUp>
                <hr />
            </div>
            <div className='px-40 xl:px-40 sm:px-20 max-sm:px-10 text-justify'>
                <div className='py-5'>
                    <img src='https://ik.imagekit.io/mhcockpit1/blogs/Navigating%20the%20Skies.webp' alt='Navigating the Skies: Understanding the Differences Between B.E. and B.Sc. in Aeronautical Engineering' />
                </div>
                <div className='py-5 text-xl'>
                    <FadeInSectionDownToUp>
                        <p>
                            In the aviation and aerospace industry, individuals aiming for a career in aeronautics often choose between a B.E. and B.Sc. in Aeronautical Engineering. Both programs focus on aeronautics but differ in their approach and areas of emphasis. Below, we delve into the distinctions between these degrees to guide students in making informed academic choices.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-3xl max-sm:text-xl font-semibold'>
                    <FadeInSectionDownToUp>
                        <p>
                            Passion for Flight
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-xl'>
                    <FadeInSectionDownToUp>
                        <p>
                            One of the most compelling reasons to explore B.E. and B.Sc. in Aeronautical Engineering is the intrinsic passion for flight. If soaring above the clouds, navigating intricate airspace, and contributing to a globally connected industry excites you, these programs offer an entry into a world of adventure and technical expertise.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-3xl max-sm:text-xl font-semibold'>
                    <FadeInSectionDownToUp>
                        <p>
                            Focus and Emphasis
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-xl'>
                    <FadeInSectionDownToUp>
                        <p>
                            The primary distinction lies in the focus of the two programs. A B.E. in Aeronautical Engineering emphasizes aircraft design, propulsion systems, and aerospace technology. The curriculum delves into engineering principles, equipping students with the technical expertise to design and maintain aircraft.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-xl'>
                    <FadeInSectionDownToUp>
                        <p>
                            In contrast, a B.Sc. in Aeronautical Science provides a broader focus, combining scientific and operational facets of aviation. Coursework spans aerodynamics, meteorology, aviation safety, and flight operations, preparing students for diverse roles that extend beyond engineering.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-3xl max-sm:text-xl font-semibold'>
                    <FadeInSectionDownToUp>
                        <p>
                            Curriculum and Coursework
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-xl'>
                    <FadeInSectionDownToUp>
                        <p>
                            B.E. in Aeronautical Engineering: The program builds on foundational subjects like mathematics, physics, and mechanics. Advanced topics include aerodynamics, aircraft structures, propulsion systems, and avionics. The focus is on engineering design, problem-solving, and analytical skills.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-xl'>
                    <FadeInSectionDownToUp>
                        <p>
                            B.Sc. in Aeronautical Science: This curriculum integrates scientific and operational courses, including meteorology, navigation, air traffic control, and aviation regulations. The program aims to prepare students for operational and management roles in the aviation industry alongside technical careers.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-3xl max-sm:text-xl font-semibold'>
                    <FadeInSectionDownToUp>
                        <p>
                            Career Paths
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-xl'>
                    <FadeInSectionDownToUp>
                        <ul>
                            <div className='flex'>
                                <span className='font-bold mr-2'>&#9992;</span><li className='font-semibold'>Graduates of B.E. in Aeronautical Engineering often pursue roles in:</li>
                            </div>
                            <div className='pl-12 py-5'>
                                <li className='list-disc'>Aircraft design and manufacturing.</li>
                                <li className='list-disc'>Aerospace engineering and research.</li>
                                <li className='list-disc'>Defence and space exploration projects.</li>
                            </div>
                        </ul>
                    </FadeInSectionDownToUp>
                    <FadeInSectionDownToUp>
                        <ul>
                            <div className='flex'>
                                <span className='font-bold mr-2'>&#9992;</span><li className='font-semibold'>Meanwhile, B.Sc. in Aeronautical Science graduates are well-suited for careers in:</li>
                            </div>
                            <div className='pl-12 pt-5'>
                                <li className='list-disc'>Aviation operations and management.</li>
                                <li className='list-disc'>Pilot training and aviation safety.</li>
                                <li className='list-disc'>Air traffic control and navigation.</li>
                            </div>
                        </ul>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-3xl max-sm:text-xl font-semibold'>
                    <FadeInSectionDownToUp>
                        <p>
                            Conclusion
                        </p>
                    </FadeInSectionDownToUp>
                </div>
                <div className='py-5 text-xl'>
                    <FadeInSectionDownToUp>
                        <p>
                            Choosing between a B.E. and B.Sc. in Aeronautical Engineering depends on individual interests and career aspirations. A B.E. focuses on engineering and design, ideal for those passionate about the technical aspects of aerospace. A B.Sc., with its broader curriculum, is perfect for individuals interested in operational roles and management within the aviation sector. Both paths offer exciting opportunities within the dynamic and evolving field of aeronautics.
                        </p>
                    </FadeInSectionDownToUp>
                </div>
            </div>
        </div>
    )
}
